import { Component, OnInit, Inject } from '@angular/core';
import { Router} from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { HeaderService } from './header.service';
import { IProfileLinks } from '../../../models/profilelink.model';
import { IProduct } from './header.model';
import { AppService } from '../../app.service'
import { OAuthService } from 'angular-oauth2-oidc'
import { EntitlementService } from '../../shared/service/entitlement.service'
import { GPA_PRODUCTS, USER_PROFILE_FILTER_ARGS, ISO_EDUCATION_FILTER_ARGS } from '../../shared/constants/app.constants'
import { JQ_TOKEN } from 'src/app/app-router.module';

declare function loadCustomerSelectionJS() : any;
declare function openCustomerSelectionModal() : any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit{
  objectKeys = Object.keys;
  userProfileLinks: IProfileLinks[];  //user drop-down menu data set
  userISOProducts: IProduct[]; //megamenu data set
  allISOProducts: IProduct[];  //megamenu data set
  categoryISOProducts: Object; //megamenu data set
  userRegisteredGPAProducts: IProduct[];

  userProfileFilterArgs = USER_PROFILE_FILTER_ARGS
  isoEducationFilterArgs = ISO_EDUCATION_FILTER_ARGS

  errorMessage: string = '';

  hasAllProducts = '';
  expandAllProductsDisabled = '';
  dropdownClass = '';

  homePageApiUrl: string = '';
  customerSupportUrl: string = ''
  isAuthenticated: boolean = false;

  hover_megamenu: string = 'allprod' 
  chatUrl:SafeResourceUrl='';
  liveAgentChatUrl: string = '';
  customerName : string = "";
  isSwitchProfileEnabled : boolean = false;
  isProfilePopupOpen : boolean = false;
  constructor(private headerService: HeaderService, 
      private router: Router, 
      private cookieService: CookieService, 
      private sanitizer: DomSanitizer,
      private appService: AppService,
      private oauthService: OAuthService,
      private entitlementService: EntitlementService,
      @Inject(JQ_TOKEN) private jQuery: any) {
        
    this.userRegisteredGPAProducts = []
    this.allISOProducts = []
    this.categoryISOProducts = {}

    let userId: string = this.cookieService.get('userid');
    let ticket: string = this.cookieService.get('ticket');
    //IPASNGEN-13415, https://stackoverflow.com/questions/37927657/unsafe-value-used-in-a-resource-url-context-with-angular-2
    this.chatUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');    
    this.appService.getGlobalConfig().subscribe(
      _config => {
        this.homePageApiUrl = _config.homePageApiUrl
        this.customerSupportUrl = _config.veriskCustomerSupport;
        this.liveAgentChatUrl = _config.liveAgentChatUrl;
        if(_config.switchProfileEnabled == "true"){
          this.isSwitchProfileEnabled = true;
          loadCustomerSelectionJS();
         this.customerName =   localStorage.getItem("customername");
         console.log("customername :" + this.customerName);
        }

        this.headerService.getProductsToHide(this.homePageApiUrl).subscribe(
          hideProducts => {
            let hideProductsList = hideProducts.split(',');
            
            this.headerService.getAllProducts(this.homePageApiUrl).subscribe(
              data => {
                data['products'] = data['products'].filter(product => !hideProductsList.includes(product.title));
                //populate category products
                data["categories"].sort((a, b) => a.title.localeCompare(b.title));
                data["categories"].forEach(c =>{
                  this.categoryISOProducts[c["title"]] =  c["products"].filter(product => !hideProductsList.includes(product.title))    
                })
                //populate all products
                data["products"].forEach(p => {
                  let item: IProduct = {
                    id: p["id"],
                    title: p["title"],
                    url: p["url"],
                    isEligible: p["isEligible"] 
                  }
                  this.allISOProducts.push(item)
                });
    
                if(ticket && userId){
                  this.headerService.getEntitledUserProfileLinks(userId, ticket, this.homePageApiUrl).subscribe(
                    _userProfileLinks => {  
                      // decorate received url for iso education and append ticket and userid to the url
                      _userProfileLinks.forEach(up => {
                        if (up.linkFor == "ISO Education") {
                          up.url += "?ticket=" + ticket + "|" + userId;
                        }
                      });
    
                      this.userProfileLinks = _userProfileLinks;
                    },
                    error => this.errorMessage = <any>error
                  );  
    
                  this.headerService.getUserProducts(userId, ticket, this.homePageApiUrl).subscribe(
                    _userProducts => {
                      // populate user products
                      this.userISOProducts = _userProducts;
                      
                      _userProducts.sort((a, b) => a.title.localeCompare(b.title));

                      this.hover_megamenu = 'myprod';
                      _userProducts.forEach(p => {
                        if(GPA_PRODUCTS.has(p.title)){
                          this.userRegisteredGPAProducts.push(p);
                        }
                        //reset eligible products in all products
                        let index = this.allISOProducts.findIndex((element) => {
                          return element.title == p.title
                        })
                        if(index > -1){
                          this.allISOProducts[index].isEligible = true
                        }
                        //reset eligible products in category products
                        for(let key in this.categoryISOProducts){
                          let i = this.categoryISOProducts[key].findIndex((element) => {
                            return element.title == p.title
                          })
                          if(i > -1){
                            this.categoryISOProducts[key][i].isEligible = true
                          }
                        }
                      })
                      this.entitlementService.setUserEligibleGPAProducts(this.userRegisteredGPAProducts);
                    },
                    error => this.errorMessage = <any>error
                  );
                }
              }
            );
          }
        );
      },
      error => this.errorMessage = <any>error
    );
  }
  
  ngOnInit(): void {  
    this.entitlementService.getLoginStatus().subscribe(
      status => {
        this.isAuthenticated = status
      }
    )
  }

  signin(): void {
    this.oauthService.initImplicitFlow();
  }

  signout(): void {
    this.cookieService.deleteAll('/', '.iso.com');
    this.entitlementService.setLoginStatus(false);
    this.oauthService.logOut();
  }

  switchProfileClick()  {
    openCustomerSelectionModal();
    this.isProfilePopupOpen = true;
  }

  hoverIn(category: string) {
    this.hover_megamenu = category;
  }

  productHoverIn() {
    if(this.isAuthenticated == true) {
      this.hover_megamenu = 'myprod';
    }
    else {
      this.hover_megamenu = 'allprod';
    }
  }

  onChatClick(): void{
    let url =  `/assets/chat.html?endpoint=${this.liveAgentChatUrl}%26ignore=${Math.floor(Math.random() * 1000)}`;
    this.chatUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Adobe Analytics Tracking
   * @param title 
   */
  productSelectTrack(title: string): void{
    window.sessionStorage.setItem('product_select', title);
  }

}
